import axios from "axios";
import {CreateGetSessionRequest} from "llb-session-api-interfaces";

const baseUrl: string = process.env.NEXT_PUBLIC_BRYA_API_BASE_URL? process.env.NEXT_PUBLIC_BRYA_API_BASE_URL : '';
const timeoutMS: number = process.env.API_TIMEOUT_MS? parseInt(process.env.API_TIMEOUT_MS) : 10000;

const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: timeoutMS,
    headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json; charset=utf-8'
    },
    responseType: "json"
});

export const baseApiGetFetcher = async (url: string) => {
//{ withCredentials: true }
    const res = await axiosInstance.get(url,{ method: 'GET'});

    if (!res.data) {
        throw Error("There was an error in fetcher",  res.data.message);
    }

    return res.data;
};

export const baseApiGetSessionFetcher = async (url: string, headerData?: CreateGetSessionRequest) => {

    const res = await axiosInstance.get(url,{
        method: 'GET',
        withCredentials: true,
    })

    if (!res.data) {
        throw Error("There was an error in fetcher",  res.data.message);
    }

    return res.data;
};
export const baseApiPostFetcher = async (url: string, postData?: any) => {
    const res = await axiosInstance.post(url, postData,{
        method: 'POST',
        withCredentials: true,
    });

    if (!res.data) {
        throw Error("There was an error in fetcher",  res.data.message);
    }

    return res.data;
};


