
export enum MediaTypeID {
    Image = "IMAGE",
    Video = "VIDEO",
    Audio = "AUDIO"
}

export enum IconFontAwesomeString {
    NONE = 'NONE',
    FaAuto = 'faAutomobile',
    FaBuildingColumns = 'faBuildingColumns',
    FaHouseChimney = 'faHouseChimney',
    FaMotorcycle = 'faMotorcycle',
    FaPeopleRoof = 'faPeopleRoof',
}



export class Media {
    readonly id: string;
    readonly mediaTypeID : MediaTypeID;
    readonly sourceURL: string;
    readonly altText: string;
    readonly caption: string;
    readonly height: number;
    readonly width: number;
    constructor(mediaTypeID: MediaTypeID, id: string, sourceURL: string, altText: string, caption: string, height: number, width: number) {
        this.id = id;
        this.mediaTypeID = mediaTypeID;
        this.sourceURL = sourceURL;
        this.altText = altText;
        this.caption = caption;
        this.height = height;
        this.width = width;
    }

}
