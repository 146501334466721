import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faBuildingColumns, faAutomobile, faHouseChimney, faMotorcycle, faPeopleRoof} from "@fortawesome/free-solid-svg-icons";
import {IconFontAwesomeString} from "@/modules/classes/media/MediaClasses";

export function getFontAwesomeIconProps(iconString: IconFontAwesomeString) : IconProp {
    let returnIcon: IconProp = faBuildingColumns;
    switch (iconString) {
        case IconFontAwesomeString.NONE:
            returnIcon = faBuildingColumns
            break;
        case IconFontAwesomeString.FaAuto:
            returnIcon = faAutomobile
            break;
        case IconFontAwesomeString.FaHouseChimney:
            returnIcon = faHouseChimney
            break;
        case IconFontAwesomeString.FaMotorcycle:
            returnIcon = faMotorcycle
            break;
        case IconFontAwesomeString.FaBuildingColumns:
            returnIcon = faBuildingColumns
            break;
        case IconFontAwesomeString.FaPeopleRoof:
            returnIcon = faPeopleRoof
            break;
    }
    return returnIcon;
}