
import {IconFontAwesomeString, Media, MediaTypeID} from "../media/MediaClasses";
import {ProductReviewEntries, ProductReviewEntry} from "./ProductReviewClasses";
import {ProductFAQ} from "./ProductFAQsClasses";
import {getProductCategories} from "@/modules/data/ProductCategoryData";

export enum ProductCategoryID {
    Auto = "AUTO",
    Home = "HOME",
    Health = "HEALTH",
    Life = "LIFE",
    Motorcycle = "CYCLE",
    Watercraft = "BOAT",
    Commercial = "COMMERCIAL",
    None = "NONE"
}

export enum LineOfBusinessID {
    PersonalLines = "PERSONAL",
    CommercialLines = "COMMERCIAL"
}
export enum CoverageTypeID {
    Liability = "LIABILITY",
    PhysicalDamage = "PHYSICAL-DAMAGE",
    Discounts = "DISCOUNTS"
}
export class CoverageTypes {
    coverageTypeID: CoverageTypeID;
    coverageTypeDescription: string;
    name: string;
    description: string;

        constructor(coverageTypeID:CoverageTypeID,coverageTypeDescription: string, name: string, description: string ) {
            this.coverageTypeID = coverageTypeID;
            this.coverageTypeDescription = coverageTypeDescription;
            this.name = name;
            this.description = description;

}
}

export class ProductCategory {
    productCategoryID: ProductCategoryID;
    lineOfBusinessID: LineOfBusinessID;
    name: string;
    title: string;
    baseUrl: string;
    shortDescription: string;
    description: string;
    description2: string;
    href: string;
    faIconString: IconFontAwesomeString;
    heroImage: Media;
    keyFeatureBullets: Array<string>;
    coverageTypes: Array<CoverageTypes>;
    reviewEntries: ProductReviewEntries;
    questions: Array<ProductFAQ>;

    constructor(productCategoryID :ProductCategoryID, name: string, title: string, baseUrl: string, shortDescription: string,description: string, description2: string,lineOfBusinessID: LineOfBusinessID, href:string, faIconString?: IconFontAwesomeString, heroImage?: Media,
                keyFeatureBullets?: [string], coverageTypes?: Array<CoverageTypes>, reviewEntries? : ProductReviewEntries, questions? : Array<ProductFAQ>) {
        this.productCategoryID = productCategoryID
        this.name = name
        this.title = title
        this.baseUrl = baseUrl
        this.shortDescription = shortDescription
        this.description = description
        this.description2 = description2
        this.lineOfBusinessID = lineOfBusinessID
        this.href = href
        if (typeof faIconString != 'undefined') {
            this.faIconString = faIconString
        } else {
            this.faIconString = IconFontAwesomeString.NONE;
        }
        if (typeof heroImage != 'undefined') {
            this.heroImage = heroImage
        } else {
            this.heroImage = new Media(MediaTypeID.Image,'','','','',0,0)
        }
        if (typeof keyFeatureBullets != 'undefined') {
            this.keyFeatureBullets = keyFeatureBullets
        } else {
            this.keyFeatureBullets = new Array<string> ('')
        }
        if (typeof coverageTypes != 'undefined') {
            this.coverageTypes = coverageTypes
        } else {
            this.coverageTypes = new Array<CoverageTypes>
        }
        if (typeof reviewEntries != 'undefined') {
            this.reviewEntries = reviewEntries
        } else {
            let reviews = new Array<ProductReviewEntry>;
            this.reviewEntries = new ProductReviewEntries(5,reviews);
        }
        if (typeof questions != 'undefined') {
            this.questions = questions
        } else {
            this.questions = new Array<ProductFAQ>;
        }

    }
}

export function getProductCategory(productCategoryID: ProductCategoryID) : ProductCategory {
    let productCategories: Array<ProductCategory> = getProductCategories();
    let selectedCategory: ProductCategory = new ProductCategory(ProductCategoryID.None, '', '','','','','',LineOfBusinessID.PersonalLines, '');
    for (let category of productCategories) {
        if (category.productCategoryID == productCategoryID) {
            selectedCategory = category
            break
        }
    }
    return selectedCategory;
}

export function getProductCategoryByBaseUrl(baseUrl: string) : ProductCategory {
    let productCategories: Array<ProductCategory> = getProductCategories();
    let selectedCategory: ProductCategory = new ProductCategory(ProductCategoryID.None, '', '','','','','',LineOfBusinessID.PersonalLines, '');
    for (let category of productCategories) {
        if (category.baseUrl == baseUrl) {
            selectedCategory = category
            break
        }
    }
    return selectedCategory;
}
