
import {IconFontAwesomeString, MediaTypeID} from "@/modules/classes/media/MediaClasses";
import {
    CoverageTypeID,
    LineOfBusinessID,
    ProductCategory,
    ProductCategoryID
} from "@/modules/classes/products/ProductCategories";

/*OLD Auto sourceURL: 'https://images.unsplash.com/photo-1478535972323-ed7fb451c937?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80https://images.unsplash.com/photo-1478535972323-ed7fb451c937?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60&sat=-50'
OLH HOME https://images.unsplash.com/photo-1610569244414-5e7453a447a8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80https://images.unsplash.com/photo-1610569244414-5e7453a447a8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60&sat=-50
OLD LIFE https://images.unsplash.com/photo-1543342384-1f1350e27861?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80https://images.unsplash.com/photo-1543342384-1f1350e27861?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60&sat=-50',
OLD CYCLE https://images.unsplash.com/photo-1681321268279-7cfb872bdf59?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60&sat=-50
OLD COMMERCIAL https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60&sat=-50
*/

export function getProductCategories() : Array<ProductCategory> {
    /**  {
     *      productCategoryID : ProductCategoryID.Health,
     *      name: 'Health',
     *      description: 'Health insurance coverage for you or your whole family.',
     *      href: '#',
     *      faIcon: faKitMedical
     *   },
     */
    return [
        {
            productCategoryID: ProductCategoryID.Auto,
            name: 'Auto',
            title: 'Auto Insurance - Drive with Confidence',
            baseUrl: 'auto-insurance',
            shortDescription: 'Protection for your car(s) from accident, theft, fire, or vandalism.',
            description:
                'At Brya Insurance, we understand that your car is more than just a vehicle; it\'s an essential part of your daily life. Whether you use it for commuting, family road trips, or weekend getaways, protecting your car and yourself is of the utmost importance. That\'s where our Comprehensive Auto Insurance comes in.' +
                '\n',
            description2:
                'Our Comprehensive Auto Insurance provides you with peace of mind and financial security on the road. It goes beyond the basics, offering a wide range of coverage options tailored to your unique needs. With us, you\'re not just another policyholder; you\'re part of our family, and we\'ll go the extra mile to keep you protected.',
            lineOfBusinessID: LineOfBusinessID.PersonalLines,
            href: '/products/champaign-urbana-savoy/auto-insurance',
            faIconString: IconFontAwesomeString.FaAuto,
            heroImage:
                {
                    mediaTypeID: MediaTypeID.Image,
                    id: 'https://bryainsurance.com/#/schema/auto-insurance/hero-image/',
                    sourceURL: '/images/brya-auto-insurance-hero.avif',
                    altText: '',
                    caption: '',
                    height: 0,
                    width: 0
                },
            keyFeatureBullets: [
                'At Brya, we\'re committed to providing you with the best coverage and exceptional customer service. Our experienced team of insurance experts will work closely with you to design an auto insurance plan that suits your budget and lifestyle.',
                'Get behind the wheel with confidence, knowing you\'re protected by Brya. Contact us today for a personalized quote and experience the difference of having a dedicated insurance partner by your side. Drive with confidence - drive with us.',
                'We offer a free, painless  consultation to build a plan that is right for you '
            ],

            coverageTypes: [
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Liability Protection",
                    description: "Safeguard yourself against legal and financial liabilities arising from accidents where you're at fault. Our liability protection ensures you're covered for bodily injury and property damage to others."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Uninsured/Underinsured Motorist Coverage",
                    description: "Unfortunately, not everyone on the road is adequately insured. Our coverage protects you and your passengers if you're involved in an accident with an uninsured or underinsured driver."
                },
                {
                    coverageTypeID: CoverageTypeID.PhysicalDamage,
                    coverageTypeDescription: "Physical Damage",
                    name: "Collision Coverage",
                    description: "Accidents can happen at any time, but with our collision coverage, you won't have to worry about costly repairs or replacements. We'll help get you back on the road quickly and efficiently."
                },
                {
                    coverageTypeID: CoverageTypeID.PhysicalDamage,
                    coverageTypeDescription: "Physical Damage",
                    name: "Comprehensive Coverage",
                    description: "Whether you car is stolen, vandalized, damaged by fire or falling objects; this coverage help pay to repair or replace your vehicle."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Personal Injury Protection (PIP)",
                    description: "Your well-being is paramount to us. PIP covers medical expenses, lost wages, and other essential services for you and your passengers, regardless of who's at fault."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Roadside Assistance",
                    description: "Enjoy peace of mind on every journey with our 24/7 roadside assistance. Whether it's a flat tire, a dead battery, or running out of fuel, help is just a phone call away."
                },
                {
                    coverageTypeID: CoverageTypeID.Discounts,
                    coverageTypeDescription: "Discounts",
                    name: "Discounts & Rewards",
                    description: "We believe in rewarding safe drivers. Ask us about our discounts for good driving records, safe vehicles, multi-policy bundling, and more."
                },
            ],
            reviewEntries: {
                starRating: 4,
                reviews:
                    [
                        {
                            ID: 1,
                            title: "Great coverage for the price",
                            content: "Came with a challenge with teenage drivers and they help me save hundreds",
                            starRating: 5,
                            previewContent: "Came with a challenge with teenage drivers and they help me save hundreds",
                            expandContent: "",
                            author: "Emily Selman",
                            authorImageURL: 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
                            dateCreated: new Date('2021-07-16'),
                            dateModified: new Date('2021-07-16'),
                        }
                    ],
            },
            questions:
                [
                    {
                        ID: 1,
                        question: "How do I get insurance for my teenage driver",
                        answer: "Getting auto insurance for a teenage child driver can be a significant decision. Teenagers often face higher insurance rates due to their lack of driving experience and statistically higher accident rates among this age group. Here is some advice to help you navigate this process.  Brya will help you shop around for quotes and make decisions such as adding to your policy or getting a new policy, seeking safe driver and good student discounts, encourage responsible driving."
                    },
                    {
                        ID: 2,
                        question: "What is auto insurance?",
                        answer: "Auto insurance is a contract between you and an insurance company where you pay regular premiums, and in return, the insurer provides financial protection in case of accidents, theft, or damage to your vehicle. It can also cover liability for injuries or property damage you may cause to others while driving."
                    },
                    {
                        ID: 3,
                        question: "Is auto insurance mandatory?",
                        answer: "Yes, in most states in the United States, auto insurance is mandatory. Each state sets its own minimum coverage requirements that drivers must meet. Driving without insurance can result in fines, license suspension, or other penalties."
                    },
                    {
                        ID: 4,
                        question: "What does auto insurance typically cover?",
                        answer: "Liability Coverage: Covers the cost of injuries and property damage you may cause to others in an accident.  Collision Coverage: Pays for repairs to your vehicle if it's damaged in a collision.  Comprehensive Coverage: Covers damage to your vehicle from non-collision incidents like theft, vandalism, or natural disasters.  Uninsured/Underinsured Motorist Coverage: Protects you if you're in an accident with a driver who has little or no insurance."
                    },
                    {
                        ID: 5,
                        question: "How are auto insurance premiums calculated?",
                        answer: "Several factors influence your auto insurance premiums, including your driving history, age, gender, location, type of vehicle, coverage options, and deductible. Safer drivers with a clean record often receive lower premiums."
                    },
                    {
                        ID: 6,
                        question: "What is a deductible?",
                        answer: "A deductible is the amount you agree to pay out of pocket before your insurance coverage kicks in. Choosing a higher deductible can lower your premium, but be sure you're comfortable covering that amount if you need to make a claim."
                    },
                    {
                        ID: 7,
                        question: "Can I customize my auto insurance policy?",
                        answer: "Yes, most insurance companies offer customizable policies to fit your specific needs. You can adjust coverage limits, add optional coverages like roadside assistance or rental car reimbursement, and tailor your policy to match your circumstances."
                    },
                    {
                        ID: 8,
                        question: "How do I file an auto insurance claim?",
                        answer: "To file a claim, contact your insurance company as soon as possible after an accident or incident. They will guide you through the process, gather necessary information, and help you get your vehicle repaired or replaced."
                    },
                    {
                        ID: 9,
                        question: "Will my insurance rates increase after an accident?",
                        answer: "Accident-related rate increases can vary depending on your policy, the severity of the accident, and your driving history. Some insurers offer accident forgiveness programs that prevent your rates from increasing after your first accident."
                    },
                    {
                        ID: 10,
                        question: "Do I need auto insurance if I'm renting a car?",
                        answer: "If you're renting a car, your existing auto insurance policy might offer coverage for rental vehicles. However, it's a good idea to check with your insurer and consider purchasing additional coverage from the rental car company if needed."
                    },
                    {
                        ID: 11,
                        question: "How can I save on auto insurance?",
                        answer: "Bundle policies (such as auto and home insurance) with the same insurer.  Maintain a safe driving record.  Inquire about discounts for good student drivers or completing defensive driving courses.  Opt for higher deductibles if you can comfortably afford them.  Choose a vehicle with safety features."
                    }
                ]

        },
        {
            productCategoryID: ProductCategoryID.Home,
            name: 'Homeowners & Renters ',
            title: 'Your Home, Your Haven - Protecting What Matters Most',
            baseUrl: 'home-renters-insurance',
            shortDescription: 'Insurance for your home and belongings whether you are a home owner or renter .',
            lineOfBusinessID: LineOfBusinessID.PersonalLines,
            description:
                'At Brya Insurance, we know that your home is more than just a house; it\'s a place where memories are made, and dreams take shape. As a homeowner, you\'ve invested not only in the bricks and mortar but also in the safety and comfort of your loved ones. Our Homeowners Insurance is designed to safeguard your most significant investment and provide you with the peace of mind you deserve.' +
                '\n',
            description2:
                'With our comprehensive Homeowners Insurance, you\'ll be prepared for the unexpected. Whether it\'s damage from a natural disaster, theft, or liability claims, we\'ve got you covered. Our policy options can be tailored to your specific needs, ensuring you have the right protection for your unique home and belongings.',
            href: '/products/champaign-urbana-savoy/home-renters-insurance',
            faIconString: IconFontAwesomeString.FaHouseChimney,
            heroImage:
                {
                    mediaTypeID: MediaTypeID.Image,
                    id: 'https://bryainsurance.com/#/schema/home-insurance/hero-image/',
                    sourceURL: '/images/brya-home-insurance-hero.avif',
                    altText: '',
                    caption: '',
                    height: 0,
                    width: 0
                },
            keyFeatureBullets: [
                'At Brya,  we believe that every renter deserves to feel safe and secure in their home away from home. Our Rental Insurance offers the protection you need at an affordable price, allowing you to rent with confidence.',
                'Contact us today for a personalized quote and discover why so many renters trust us to protect their belongings and provide them with the peace of mind they deserve. Own or Rent with confidence - insured with us.',
                'We offer a free, painless consultation to build a plan that is right for you '
            ],
            coverageTypes: [
                {
                    coverageTypeID: CoverageTypeID.PhysicalDamage,
                    coverageTypeDescription: 'Physical Damage',
                    name: 'Dwelling Coverage',
                    description: 'Protects the structure of your home, including the walls, roof, floors, built-in appliances, and other structural components, in the event of covered perils like fire, windstorm, or vandalism.'
                },
                {
                    coverageTypeID: CoverageTypeID.PhysicalDamage,
                    coverageTypeDescription: 'Physical Damage',
                    name: 'Personal Property Coverage',
                    description: 'Covers your personal belongings, such as furniture, clothing, and electronics, if they are damaged, destroyed, or stolen.'
                },
                {
                    coverageTypeID: CoverageTypeID.PhysicalDamage,
                    coverageTypeDescription: 'Physical Damage',
                    name: 'Other Structures Coverage',
                    description: 'Protects structures on your property that are not attached to your home, such as a detached garage, shed, or fence.'
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: 'Liability',
                    name: 'Additional Living Expenses (ALE) or Loss of Use Coverage',
                    description: 'Pays for additional living expenses if you have to temporarily move out of your home due to a covered loss. This could include hotel bills, restaurant meals, and other living expenses.'
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: 'Liability',
                    name: 'Liability Coverage',
                    description: 'Provides protection if you are found responsible for injuring someone or damaging their property. It can help cover legal expenses and settlement costs.'
                }
            ],
            reviewEntries: {
                starRating: 4.5,
                reviews:
                    [
                        {
                            ID: 1,
                            title: "Brya helped me save 18% on my homeowner's insurance",
                            content: "My previous insurance company raised my rates on me this year.  Brya helped find a company that provided the same coverage for 18% less.",
                            starRating: 5,
                            previewContent: "My previous insurance company raised my rates on me this year.  Brya helped find a company that provided the same coverage for 18% less.",
                            expandContent: "",
                            author: "John",
                            authorImageURL: 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
                            dateCreated: new Date('2021-07-16'),
                            dateModified: new Date('2021-07-16'),
                        }
                    ],
            },
            questions: [
                {
                    ID: 1,
                    question: "What does a homeonwer's policy typically cover?",
                    answer: 'A homeowner\'s insurance policy typically covers the following aspects: Dwelling, Personal Property, Additional Living Expenses (ALE), Liability',
                },
                {
                    ID: 2,
                    question: "What does a renter's policy typically cover?",
                    answer: 'A renter\'s insurance policy typically covers the following aspects: Personal Property, Additional Living Expenses (ALE), Liability',
                },
                {
                    ID: 3,
                    question: "How do I determine how much coverage I will need?",
                    answer: 'Work with your insurance agent who will help you determine the replacement cost of your home, assess the value of your personal belongings, evaluate your budget and risk tolerance to find the mix of coverages best for your situation.',
                },
                {
                    ID: 4,
                    question: "What is a deductible and how does it affect my quote?",
                    answer: 'This is the out-of-pocket expense the policyholder must pay before the insurance coverage kicks in.  The higher the deductible, the lower the price of coverage.',
                },
                {
                    ID: 5,
                    question: "How do I insure our high-value items in the home like jewelry, electronics, or art?",
                    answer: 'Insuring high-value items like jewelry, electronics, or art on your homeowners policy typically involves considering additional coverage options, as standard policies may have limits on coverage for certain types of property. ',
                },
                {
                    ID: 6,
                    question: "Am I covered for flood or other natural disasters?",
                    answer: 'It\'s important to note that standard homeowner\'s insurance policies may not cover certain events, such as floods and earthquakes. Additional coverage or separate policies may be needed for these specific perils.',
                },
                {
                    ID: 7,
                    question: "How can I save on howeonwer's or renter's insurance?",
                    answer: 'Work with your Brya agent to determine the right level of coverage.  The agent will then shop to evaluate rates of different companies we represent to maximize discounts, such as multi-policy discounts, loyalty discounts, or safety feature discounts and minimize the price.',
                },
            ],
        },
        {
            productCategoryID: ProductCategoryID.Life,
            name: 'Life',
            title: 'Securing Your Legacy - Life Insurance for a Brighter Future',
            baseUrl: 'life-insurance',
            shortDescription: 'Protection and financial security for your family and loved ones.',
            lineOfBusinessID: LineOfBusinessID.PersonalLines,
            description:
                'Life is full of unpredictable twists and turns, and protecting your loved ones\' financial future is paramount. At Brya Insurance, we understand that your family\'s well-being is your top priority. Our Life Insurance is designed to provide you with peace of mind, knowing that your legacy and the ones you cherish most are safeguarded.' +
                '\n',
            description2:
                'Life Insurance is more than just a policy; it\'s a gift of love and security that lasts a lifetime. With our comprehensive life insurance coverage, you can rest assured that your family\'s financial needs will be taken care of in the event of the unexpected. It\'s a small step that leads to a brighter future for those you leave behind.',
            href: '/products/champaign-urbana-savoy/life-insurance',
            faIconString: IconFontAwesomeString.FaPeopleRoof,
            heroImage:
                {
                    mediaTypeID: MediaTypeID.Image,
                    id: 'https://bryainsurance.com/#/schema/life-insurance/hero-image/',
                    sourceURL: '/images/brya-life-insurance-hero.avif',
                    altText: '',
                    caption: '',
                    height: 0,
                    width: 0
                },
            keyFeatureBullets: [
                'At Brya, we understand that your life\'s journey is unique, and so should be your life insurance policy. Our experienced agents will work closely with you to design a plan that aligns with your financial goals and priorities.',
                'Secure your legacy and protect those you cherish most with our Life Insurance. Contact us today for a personalized quote and discover why countless individuals trust us to provide a brighter future for their loved ones. Securing your legacy - insured with us.'
            ],
            coverageTypes: [
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Term Life Insurance",
                    description: "Provides coverage for a specific term, typically 10, 15, 20, or 30 years. It pays out a death benefit if the policyholder passes away during the term. It's generally more affordable but does not accumulate cash value."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Whole Life Insurance",
                    description: "Offers coverage for the entire life of the policyholder. Premiums are generally higher than term life insurance, but it has a cash value component that grows over time and can be borrowed against or withdrawn."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Universal Life Insurance",
                    description: "Similar to whole life insurance but offers more flexibility. Policyholders can adjust the premium payments and death benefits over time. It also has a cash value component that earns interest."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Variable Life Insurance",
                    description: "Combines a death benefit with an investment component. Policyholders can allocate their cash value among various investment options, such as stocks and bonds. The cash value and death benefit can fluctuate based on the performance of the investments."
                }
            ],
            reviewEntries: {
                starRating: 4.8,
                reviews:
                    [
                        {
                            ID: 1,
                            title: "Brya helped me to sleep better at night!",
                            content: "I had been putting off getting life insurance for a while, but after a recent health scare; got my act together and Brya helped me find affordable coverage that allows me to know my family will be taken care of if something happens to me.",
                            starRating: 5,
                            previewContent: "I had been putting off getting life insurance for a while, but after a recent health scare; got my act together and Brya helped me find affordable coverage that allows me to know my family will be taken care of if something happens to me.",
                            expandContent: "",
                            author: "John",
                            authorImageURL: 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
                            dateCreated: new Date('2021-07-16'),
                            dateModified: new Date('2021-07-16'),
                        }
                    ],
            },
            questions: [
                {
                    ID: 1,
                    question: "What types of life insurance are available, and which one is right for me?",
                    answer: "Types include term, whole life, universal life, and variable life. The right one depends on your financial goals and preferences."
                },
                {
                    ID: 2,
                    question: "How much life insurance coverage do I need?",
                    answer: "It varies based on factors like income, debts, and future financial needs. A common rule of thumb is 5 to 10 times your annual income."
                },
                {
                    ID: 3,
                    question: "What is the difference between term life and whole life insurance?",
                    answer: "Term provides coverage for a specific period, while whole life lasts your entire life and has a cash value component."
                },
                {
                    ID: 4,
                    question: "How does the underwriting process work, and will I need a medical exam?",
                    answer: "Underwriting assesses your risk. A medical exam is often required, but some policies offer no-exam options."
                },
                {
                    ID: 5,
                    question: "Can I get life insurance if I have pre-existing health conditions?",
                    answer: "It depends on the condition, but some policies cater to specific health situations."
                },
                {
                    ID: 6,
                    question: "What factors determine the cost (premium) of life insurance?",
                    answer: "Factors include age, health, coverage amount, and the type of policy. Riskier factors may increase premiums."
                },
                {
                    ID: 7,
                    question: "Can I adjust my coverage amount later if my circumstances change?",
                    answer: "Some policies allow adjustments, but it's important to check the terms."
                },
                {
                    ID: 8,
                    question: "What is the cash value component in whole life insurance, and how does it work?",
                    answer: "It's a savings component that grows over time. You can borrow against it or withdraw funds, but it affects the death benefit."
                },
                {
                    ID: 9,
                    question: "Can I convert my term life insurance policy to a permanent policy later?",
                    answer: "Many term policies offer conversion options, allowing you to switch to permanent coverage without a new medical exam."
                },
                {
                    ID: 10,
                    question: "How does the beneficiary designation work, and can it be changed?",
                    answer: "You can designate beneficiaries, and it's typically flexible, allowing changes when needed."
                },
                {
                    ID: 11,
                    question: "Will my life insurance policy payout be taxed?",
                    answer: "Generally, life insurance death benefits are tax-free. However, consult a tax professional for specific situations."
                },
                {
                    ID: 12,
                    question: "How long does it take for a life insurance policy to pay out after a death?",
                    answer: "Typically, claims are processed within a few weeks once all required documentation is submitted."
                }
                ]
        },

        {
            productCategoryID: ProductCategoryID.Motorcycle,
            name: 'Motorcycle',
            title: 'Motorcycle Insurance - Ride Safely, Insure Confidently',
            baseUrl: 'motorcycle-insurance',
            shortDescription: 'Protection for your motorcycle(s) from accident, theft, fire, or vandalism..',
            lineOfBusinessID: LineOfBusinessID.PersonalLines,
            description:
                'At Brya Insurance, we know that riding a motorcycle is not just a mode of transportation; it\'s a thrilling and liberating experience. Whether you\'re a daily commuter or a weekend adventurer, protecting yourself and your bike is paramount. That\'s where our Motorcycle Insurance comes in.',
            description2:
                'Our Motorcycle Insurance is designed to provide you with comprehensive coverage and peace of mind on every ride. We understand the unique risks and joys that come with owning a motorcycle, and we\'re here to ensure you\'re fully protected while you embrace the open road.',
            href: '/products/champaign-urbana-savoy/motorcycle-insurance',
            faIconString: IconFontAwesomeString.FaMotorcycle,
            heroImage:
                {
                    mediaTypeID: MediaTypeID.Image,
                    id: 'https://bryainsurance.com/#/schema/motorcycle-insurance/hero-image/',
                    sourceURL: '/images/brya-motorcycle-insurance-hero.avif',
                    altText: '',
                    caption: '',
                    height: 0,
                    width: 0
                },
            keyFeatureBullets: [
                'At Brya Insurance, we\'re passionate about motorcycles, just like you. Our experienced team of insurance experts will work closely with you to tailor a motorcycle insurance plan that aligns with your budget and lifestyle.',
                'Embrace the exhilaration of the open road, knowing you\'re protected by Brya. Contact us today for a personalized quote and ride confidently with the knowledge that you have a dedicated insurance partner by your side. Ride safely - insure with us.',
                'We offer a free, painless  consultation to build a plan that is right for you ',
            ],
            coverageTypes: [
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Liability Protection",
                    description: "Safeguard yourself against legal and financial liabilities arising from accidents where you're at fault. Our liability protection ensures you're covered for bodily injury and property damage to others."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Uninsured/Underinsured Motorist Coverage",
                    description: "Unfortunately, not everyone on the road is adequately insured. Our coverage protects you and your passengers if you're involved in an accident with an uninsured or underinsured driver."
                },
                {
                    coverageTypeID: CoverageTypeID.PhysicalDamage,
                    coverageTypeDescription: "Physical Damage",
                    name: "Collision Coverage",
                    description: "Accidents can happen at any time, but with our collision coverage, you won't have to worry about costly repairs or replacements. We'll help get you back on the road quickly and efficiently."
                },
                {
                    coverageTypeID: CoverageTypeID.PhysicalDamage,
                    coverageTypeDescription: "Physical Damage",
                    name: "Comprehensive Coverage",
                    description: "Whether you car is stolen, vandalized, damaged by fire or falling objects; this coverage help pay to repair or replace your vehicle."
                },
                {
                    coverageTypeID: CoverageTypeID.PhysicalDamage,
                    coverageTypeDescription: "Physical Damage",
                    name: "Accessories",
                    description: "Provides coverage for motorcycle accessories and custom parts, such as saddlebags, upgraded exhaust systems, or custom paint jobs."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Personal Injury Protection (PIP)",
                    description: "Your well-being is paramount to us. PIP covers medical expenses, lost wages, and other essential services for you and your passengers, regardless of who's at fault."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Roadside Assistance",
                    description: "Enjoy peace of mind on every journey with our 24/7 roadside assistance. Whether it's a flat tire, a dead battery, or running out of fuel, help is just a phone call away."
                },
                {
                    coverageTypeID: CoverageTypeID.Discounts,
                    coverageTypeDescription: "Discounts",
                    name: "Discounts & Rewards",
                    description: "We believe in rewarding safe drivers. Ask us about our discounts for good driving records, safe vehicles, multi-policy bundling, and more."
                },
            ],
            reviewEntries: {
                starRating: 4.2,
                reviews:
                    [                        {
                        ID: 1,
                        title: "Brya has kick a$$ prices",
                        content: "Saved me a bunch on my H-D.",
                        starRating: 5,
                        previewContent: "Saved $215 every 6 months.  Enough to fund purchase of some more accessories for my bike.",
                        expandContent: "",
                        author: "Tami",
                        authorImageURL: 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
                        dateCreated: new Date('2021-07-16'),
                        dateModified: new Date('2021-07-16'),
                    }]
            },
            questions: [
                {
                    ID: 1,
                    question: "What types of coverage do I need for my motorcycle?",
                    answer: "Common coverage types include liability, collision, comprehensive, uninsured/underinsured motorist, medical payments, and accessory coverage."
                },
                {
                    ID: 2,
                    question: "How much does motorcycle insurance cost?",
                    answer: "Costs vary based on factors like your riding history, the type of motorcycle, coverage types, and your location."
                },
                {
                    ID: 3,
                    question: "What factors influence the cost of motorcycle insurance?",
                    answer: "Factors include your riding experience, age, location, type of motorcycle, coverage choices, and driving record."
                },
                {
                    ID: 4,
                    question: "What is liability coverage, and how much do I need?",
                    answer: "Liability coverage pays for injuries or property damage you cause to others. Coverage limits depend on your state's requirements and your personal assets."
                },
                {
                    ID: 5,
                    question: "Does motorcycle insurance cover theft or vandalism?",
                    answer: "Yes, with comprehensive coverage, which also covers non-collision events like theft, vandalism, or natural disasters."
                },
                {
                    ID: 6,
                    question: "Do I need uninsured/underinsured motorist coverage?",
                    answer: "It's recommended, as it protects you if you're in an accident with a driver who has insufficient or no insurance."
                },
                {
                    ID: 7,
                    question: "What is comprehensive coverage, and is it necessary for my motorcycle?",
                    answer: "Comprehensive coverage protects against non-collision events and is advisable for theft, vandalism, or weather-related damage."
                },
                {
                    ID: 8,
                    question: "Can I get coverage for custom parts and accessories on my motorcycle?",
                    answer: "Yes, accessory coverage can be added to protect custom parts and accessories."
                },
                {
                    ID: 9,
                    question: "How does the claims process work for motorcycle insurance?",
                    answer: "Report the claim to your insurance provider, provide necessary information, and work with their claims department to assess damages and process the claim."
                },
                {
                    ID: 10,
                    question: "Are there any discounts available for motorcycle insurance?",
                    answer: "Common discounts include safety course completion, multi-policy discounts, and motorcycle safety features."
                },
                {
                    ID: 11,
                    question: "Can I bundle my motorcycle insurance with other policies for a discount?",
                    answer: "Yes, bundling with other policies like auto or homeowners insurance may qualify you for a discount."
                },
                {
                    ID: 12,
                    question: "Do I need motorcycle insurance year-round, even if I don't ride in the winter?",
                    answer: "Yes, maintaining continuous coverage may help you avoid gaps and potential rate increases when you resume riding."
                },
                {
                    ID: 13,
                    question: "Can I transfer my motorcycle insurance to a new bike if I upgrade or replace my current one?",
                    answer: "Yes, you can typically transfer coverage to a new motorcycle, but notify your insurer to ensure proper coverage."
                },

            ],
        },
        {
            productCategoryID: ProductCategoryID.Commercial,
            name: 'Commercial',
            title: 'Protecting Your Business, Empowering Your Success - Commercial Insurance Solutions',
            baseUrl: 'commercial-insurance',
            shortDescription: 'A wide range of commercial insurance products tailored to protect your business.',
            lineOfBusinessID: LineOfBusinessID.CommercialLines,
            description:
                'At Brya Insurance, we understand that running a business is no small feat. Your hard work, dedication, and vision have shaped your company into what it is today. As a trusted partner for commercial insurance solutions, we are committed to safeguarding your business, empowering your growth, and helping you navigate the unpredictable challenges that come your way.',
            description2:
                'Our comprehensive Commercial Insurance offers a range of coverage options tailored to meet the unique needs of businesses like yours. From small startups to established enterprises, we provide protection against various risks, allowing you to focus on what matters most - your business.',
            href: '/products/champaign-urbana-savoy/commercial-insurance',
            faIconString: IconFontAwesomeString.FaBuildingColumns,
            heroImage:
                {
                    mediaTypeID: MediaTypeID.Image,
                    id: 'https://bryainsurance.com/#/schema/commercial-insurance/hero-image/',
                    sourceURL: '/images/brya-commercial-insurance-hero.avif',
                    altText: '',
                    caption: '',
                    height: 0,
                    width: 0
                },
            keyFeatureBullets: [
                'At Brya Insurance, we take pride in being more than just an insurance provider; we are your partner in success. Our knowledgeable agents understand the intricacies of different industries and will work with you to identify potential risks and recommend the right coverage.',
                'Secure your business\'s future and unleash its full potential with our Commercial Insurance Solutions. Contact us today for a personalized quote and experience the peace of mind that comes from having a dedicated insurance partner by your side. Protecting your business - empowering your success, insured with us.',
                'We offer a free, painless  consultation to build a plan that is right for you ',
            ],
            coverageTypes: [
                {
                    coverageTypeID: CoverageTypeID.PhysicalDamage,
                    coverageTypeDescription: "Physical Damage",
                    name: "Commercial Property Insurance",
                    description: "Protects a business's physical assets, including buildings, equipment, inventory, and furnishings, against perils such as fire, theft, vandalism, and certain natural disasters."
                },
                {
                    coverageTypeID: CoverageTypeID.PhysicalDamage,
                    coverageTypeDescription: "Physical Damage",
                    name: "Commercial Auto Insurance",
                    description: "Provides coverage for vehicles used for business purposes, including liability for bodily injury and property damage, as well as coverage for the business-owned vehicles themselves."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "General Liability Insurance",
                    description: "Covers third-party bodily injury, property damage, and personal injury claims that may arise during business operations. It's a fundamental coverage for businesses to protect against lawsuits."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Workers' Compensation Insurance",
                    description: "Mandatory in many jurisdictions, it covers medical expenses and lost wages for employees who are injured or become ill while on the job. It also protects businesses from potential employee lawsuits related to workplace injuries."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Business Interruption Insurance",
                    description: "Helps cover lost income and operating expenses when a covered event, such as a fire or natural disaster, forces a business to temporarily close or relocate."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Professional Liability (Errors and Omissions) Insurance",
                    description: "Protects professionals against claims of negligence, errors, or omissions in their professional services. It is crucial for businesses in professions like law, accounting, and consulting."
                },
                {
                    coverageTypeID: CoverageTypeID.PhysicalDamage,
                    coverageTypeDescription: "Physical Damage",
                    name: "Commercial Crime Insurance",
                    description: "Covers financial losses resulting from crimes such as employee theft, fraud, and forgery."
                },
                {
                    coverageTypeID: CoverageTypeID.Liability,
                    coverageTypeDescription: "Liability",
                    name: "Liquor Liability Insurance",
                    description: "Protects businesses that sell or serve alcoholic beverages against liability for alcohol-related injuries or damages caused by intoxicated patrons."
                },
            ],
            reviewEntries: {
                starRating: 4.7,
                reviews:
                    []
            },
            questions: [        {
                ID: 1,
                question: "What types of commercial insurance coverage do I need for my business?",
                answer: "Coverage needs depend on your business type. Common types include property insurance, liability insurance, workers' compensation, and business interruption insurance."
            },
                {
                    ID: 2,
                    question: "How is the cost of commercial insurance determined?",
                    answer: "Factors include business size, industry, location, coverage types, and the business's risk profile."
                },
                {
                    ID: 3,
                    question: "Are there industry-specific insurance requirements for my business?",
                    answer: "Yes, certain industries may have specific insurance requirements or regulations."
                },
                {
                    ID: 4,
                    question: "What does commercial property insurance cover, and what are the coverage limits?",
                    answer: "It covers property damage due to perils like fire, theft, and natural disasters. Coverage limits depend on the property's value and replacement cost."
                },
                {
                    ID: 5,
                    question: "What is included in general liability insurance, and how much coverage is recommended?",
                    answer: "It covers third-party bodily injury, property damage, and personal injury claims. Recommended coverage varies, but $1 million is common."
                },
                {
                    ID: 6,
                    question: "Do I need workers' compensation insurance, and how does it work?",
                    answer: "In many places, it's required if you have employees. It covers medical expenses and lost wages for employees injured on the job."
                },
                {
                    ID: 7,
                    question: "Are there ways to save on commercial insurance premiums?",
                    answer: "Bundle policies, implement safety measures, and shop around for competitive rates."
                },
                {
                    ID: 8,
                    question: "What types of events or perils are covered under business interruption insurance?",
                    answer: "It covers income loss due to events like fire, natural disasters, or other covered perils that force a temporary shutdown."
                },
                {
                    ID: 9,
                    question: "Do I need cyber liability insurance, and what does it cover?",
                    answer: "It's recommended for businesses that handle sensitive data. It covers costs related to data breaches, cyberattacks, and privacy violations."
                },
                {
                    ID: 10,
                    question: "How can I protect my business from lawsuits, and what liability coverages are available?",
                    answer: "Liability coverages such as general liability, professional liability, and product liability help protect against lawsuits."
                },
                {
                    ID: 11,
                    question: "What is professional liability (errors and omissions) insurance, and do I need it?",
                    answer: "It protects against claims of professional negligence or mistakes in providing services. It's crucial for certain professions."
                },
                {
                    ID: 12,
                    question: "Is commercial auto insurance necessary for my business vehicles?",
                    answer: "Yes, if your business owns or uses vehicles for business purposes, commercial auto insurance is necessary."
                },
                {
                    ID: 13,
                    question: "What factors influence the cost of commercial auto insurance?",
                    answer: "Factors include the number of vehicles, drivers, usage, and driving records."
                },
                {
                    ID: 14,
                    question: "How does umbrella insurance provide additional coverage, and when is it necessary?",
                    answer: "It provides additional liability coverage beyond the limits of primary policies. It's necessary when higher liability limits are needed."
                },
                {
                    ID: 15,
                    question: "What is covered under a commercial crime insurance policy?",
                    answer: "It covers financial losses resulting from crimes like employee theft, fraud, and forgery."
                },
                {
                    ID: 16,
                    question: "Do I need directors and officers (D&O) insurance, and how does it protect my company's leadership?",
                    answer: "It's recommended for businesses with a board of directors. It protects leaders from personal losses due to decisions made while managing the company."
                },
                {
                    ID: 17,
                    question: "Are there insurance options for specific industry risks or specialized equipment?",
                    answer: "Yes, there are industry-specific coverages and policies tailored to unique risks and equipment."
                },
                {
                    ID: 18,
                    question: "How does the claims process work for commercial insurance, and what should I do in case of a claim?",
                    answer: "Contact your insurance provider promptly, document the incident, and cooperate in the claims process."
                },
                {
                    ID: 19,
                    question: "Can I customize my commercial insurance policy to fit the unique needs of my business?",
                    answer: "Yes, commercial insurance policies can often be tailored to meet the specific needs and risks of your business."
                },
                {
                    ID: 20,
                    question: "What is the financial stability and reputation of the insurance provider?",
                    answer: "Research ratings from independent agencies, read reviews, and choose a reputable provider with a history of financial stability."
                }],
        },
    ];
}